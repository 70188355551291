

.block-links.message {
  padding: 8px 0;

  a {
    display: block;
    //max-width: 80%;
    margin-bottom: 8px;
    padding: 5px 16px;
    color: $color-text;
    //color: $color-primary;
    font-weight: 500;
    background-color: #f1f3f7;
    text-decoration: none;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid $color-divider;
    //border-left: 3px solid $color-primary;
    transition: 0.3s ease-out;

    opacity: 0;
    transform: translateY(44px);
    transition: 0.5s ease-out;

    &::after {
      content: '\203A';
      float: right;
      font-size: 20px;
      margin-top: -7px;
      color: $color-primary;
      transform: translateX(-8px);
      opacity: 0;
      transition: all 0.3s ease-out;
    }

    &:hover {
      //background-color: #f2f4f5;
      color: $color-primary;
      border-color: $color-primary;
      padding-left: 24px;

      &::after {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  @for $i from 1 through 10 {
    $delay: ($i - 1) * 0.125;
    a:nth-child(#{$i}) { transition: all 0.5s ease-out, opacity 0.5s ease-out #{$delay}s, transform 0.5s ease-out #{$delay}s; }
  }

  &.reveal a {
    transform: translateY(0);
    opacity: 1;
  }


}

