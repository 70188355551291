
.btn-send {
  position: relative;
  border: none;
  background-color: darken($color-divider, 3%);
  //background-color: transparent;
  color: #fff;
  fill: $color-text-lighter;
  border-radius: 10rem;
  font-size: 13px;
  display: inline-block;
  width: 44px;
  height: 44px;
  margin: -4px;
  outline: none;
  cursor: pointer;
  z-index: 9;
  transition: 0.3s ease-out;

  &::after {
    content: '';
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="white" d="M48 448l416-192L48 64v149.333L346 256 48 298.667z"/></svg>');
    display: inline-block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -9px;
  }

  svg {
    display: none;
    width: 24px;
    padding-left: 2px;
    margin-left: 4px;
    transition: margin-left 0.2s ease-out;
  }

  &:hover {
    background-color: lighten($color-primary, 20%);
    fill: $color-primary;
    transform: scale(1.09,1.09);
  }

  &:focus {
    outline: none;
  }
}
