
.block-appointment.editor {
  //text-align: right;
  display: flex;
  border-radius: 10rem;
  border: 1px solid $color-divider;
  padding: 2px;
  padding-bottom: 0;
  max-width: 90%;

  opacity: 0;
  transform: translateY(44px);
  transition: 0.5s ease-out;

  &.reveal {
    transform: translateY(0);
    opacity: 1;
  }

  input {
    border: none;
    border-radius: 10rem;
    outline: none;
    padding-left: 12px;
    height: 34px;
    //width: 280px;
  }

  .flatpickr-input {
    border-right: 1px solid $color-divider;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    max-width: 155px;
  }

  .time-slots {
    max-width: 105px;
    cursor: pointer;
  }

  .btn-send {
    flex-shrink: 0;
  }

  &.is-invalid {
    border-color: lighten($color-danger, 25%);
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }


  &.has-focus {
    border-color: lighten($color-primary, 25%);

    button {
      background-color: lighten($color-primary, 25%);
      fill: $color-primary;
    }
  }

}


.block-appointment.editor {
  .flatpickr-calendar.static {
    left: 0px;
    top: calc(100% + 16px);

    /*
    &.arrowTop {
      top: calc(100% + 16px);
    }

    &.arrowBottom {
      top: auto;
      bottom: calc(100% + 16px);
    }
    */

  }

  .flatpickr-calendar:before,
  .flatpickr-calendar:after {
    right: auto;
    left: 22px;
  }

  .flatpickr-months .flatpickr-month,
  .flatpickr-current-month {
    height: 34px;
  }


  .appo-picker {
    top: 50px !important;
    left: auto !important;
    right: 0 !important;
    max-width: 280px;
    border: none;
    box-shadow: 1px 0 0 #eee, -1px 0 0 #eee, 0 1px 0 #eee, 0 -1px 0 #eee, 0 3px 13px rgba(0,0,0,0.08);
  }

  .appo-picker-title {
    display: none;
  }

  .appo-picker-list-item input[type="button"] {
    height: 100%;
    font-size: 13px;
    border: 2px solid #fff;
    border-radius: 6px;
    padding: 0;
    margin: 1;

    &:hover,
    &:active,
    &:focus {
      color: #191919;
      background-color: #e7e8e9;
    }

    &.is-selected {
      box-shadow: none;
      color: #fff;
      background-color: $color-primary;
    }
  }


}

.chatbox li:nth-child(n+4) {
  .block-appointment.editor .flatpickr-calendar.static {
    top: auto;
    bottom: calc(100% + 16px);
  }

  .block-appointment.editor .appo-picker {
    top: auto !important;
    bottom: calc(100% + 16px);
  }
}


.block-appointment.message {
  @extend .block-reply;
}
