
.block-accordion.message {
  padding: 8px 0;
  max-width: 100%;

  .accordion-title {
    display: block;
    padding: 8px 16px;
    color: $color-text;
    font-weight: 500;
    border-radius: 3px;
    background-color: #f9fafb;
    text-decoration: none;
    transition: 0.5s ease-out;

    &::after {
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      float: right;
      border-left: 1px solid #959595;
      border-top: 1px solid #959595;
      margin-top: 10px;
      margin-bottom: 3px;
      transform: rotate(-135deg);
      transition: .2s linear;
    }

    &:hover {
      background-color: #f7f8f9;
      color: $color-text-dark;
    }
  }


  .accordion-content {
    padding: 10px 16px;
    font-size: 15px;
    display: none;

    p:last-child {
      margin-bottom: 0;
    }
  }


  .accordion-item {
    margin-bottom: 8px;
    border-radius: 3px;
    border: 1px solid $color-divider;
    transition: 0.3s ease-out;

    opacity: 0;
    transform: translateY(44px);
    transition: 0.5s ease-out;

    &.active {

      .accordion-title::after {
        transform: rotate(45deg);
      }

      .accordion-content {
        display: block;
      }

    }
  }


  @for $i from 1 through 30 {
    $delay: ($i - 1) * 0.125;
    .accordion-item:nth-child(#{$i}) { transition: all 0.5s ease-out, opacity 0.5s ease-out #{$delay}s, transform 0.5s ease-out #{$delay}s; }
  }

  &.reveal .accordion-item {
    transform: translateY(0);
    opacity: 1;
  }

}

