
.block-single_choice.editor,
.block-multiple_choice.editor,
.block-select.editor {
  text-align: right;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  margin: 0 -4px;

  a {
    //display: inline-block;
    color: $color-text;
    border: 1px solid #e8e8e8;
    border-radius: 10rem;
    padding: 2px 16px;
    text-align: center;
    cursor: pointer;
    transition: 0.3s ease-out;
    letter-spacing: 1px;
    margin: 4px;
    //white-space: nowrap;
    text-decoration: none;


    &:hover {
      color: $color-primary;
      border-color: $color-primary;
    }

    &.active {
      color: #fff;
      background-color: $color-primary;
      border-color: $color-primary;
    }

  }

  .option {
    opacity: 0;
    transform: translateY(44px);
    transition: 0.5s ease-out;
  }

  .submit {
    flex-basis: 100%;
    margin-top: 12px;
    margin-right: 4px;
  }

  &.stacked {
    flex-direction: column;
  }

  @for $i from 1 through 10 {
    $delay: ($i - 1) * 0.125;
    .option:nth-child(#{$i}) { transition: all 0.5s ease-out, opacity 0.5s ease-out #{$delay}s, transform 0.5s ease-out #{$delay}s; }
  }


  &.reveal .option {
    transform: translateY(0);
    opacity: 1;
  }

}



.block-single_choice.message,
.block-multiple_choice.message,
.block-select.message {

  text-align: right;

  .option {
    background-color: $color-primary;
    color: #fff;
    padding: 2px 1rem;
    border-radius: 17px;
    display: inline-block;
    margin-bottom: 8px;
    margin-left: 8px;
  }
}
