
.block-input.editor,
.block-short_text.editor,
.block-long_text.editor,
.block-number.editor,
.block-date.editor,
.block-email.editor {
  //text-align: right;
  display: flex;
  border-radius: 10rem;
  border: 1px solid $color-divider;
  padding: 2px;
  max-width: 90%;

  opacity: 0;
  transform: translateY(44px);
  transition: 0.5s ease-out;

  &.reveal {
    transform: translateY(0);
    opacity: 1;
  }

  &.multiline {
    border-radius: 8px;

    .btn-send {
      align-self: flex-end;
      margin-right: -15px;
      margin-bottom: -15px;
    }
  }

  input {
    border: none;
    border-radius: 10rem;
    outline: none;
    padding-left: 12px;
    height: 34px;
    width: 280px;
  }

  input[type="number"] {
    padding-right: 8px;
  }

  textarea {
    border: none;
    outline: none;
    padding-left: 12px;
    width: 340px;
    max-width: 100%;
    resize: none;
    overflow: hidden;
    min-height: 60px;
  }

  .btn-send {
    flex-shrink: 0;
  }

  /*
  button {
    border: none;
    background-color: $color-divider;
    //background-color: transparent;
    color: #fff;
    fill: $color-text-lighter;
    border-radius: 10rem;
    font-size: 13px;
    display: inline-block;
    width: 44px;
    height: 44px;
    margin: -4px;
    outline: none;
    cursor: pointer;
    z-index: 9;
    transition: 0.3s ease-out;

    svg {
      width: 24px;
      padding-left: 2px;
      margin-left: 4px;
      transition: margin-left 0.2s ease-out;
    }

    &:hover {
      background-color: lighten($color-primary, 25%);
      fill: $color-primary;
      transform: scale(1.15,1.15);
    }
  }
  */

  &.is-invalid {
    border-color: lighten($color-danger, 25%);
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }


  &.has-focus {
    border-color: lighten($color-primary, 25%);

    button {
      background-color: lighten($color-primary, 25%);
      fill: $color-primary;
    }
  }

}


.block-long_text.editor {
  border-radius: 8px;

  .btn-send {
    align-self: flex-end;
    margin-right: -15px;
    margin-bottom: -15px;
    flex-shrink: 0;
  }
}


.block-date.editor {
  .flatpickr-calendar.static {
    right: -40px;
    top: calc(100% + 16px);

    /*
    &.arrowTop {
      top: calc(100% + 16px);
    }

    &.arrowBottom {
      top: auto;
      bottom: calc(100% + 16px);
    }
    */

  }

  .flatpickr-calendar:before,
  .flatpickr-calendar:after {
    left: auto;
    right: 22px;
  }

  .flatpickr-months .flatpickr-month,
  .flatpickr-current-month {
    height: 34px;
  }
}

.chatbox li:nth-child(n+4) {
  .block-date.editor .flatpickr-calendar.static {
    top: auto;
    bottom: calc(100% + 16px);
  }
}


.block-input.message,
.block-date.message,
.block-number.message,
.block-short_text.message,
.block-long_text.message,
.block-email.message {
  @extend .block-reply;
}
