
body {
  background: transparent;

  &.no-iframed {
    background: #f4f6f8 url(../../assets/img/bg-index.png);
    background-position: center center;
    background-size: cover;
    //background: $color-primary;
  }
}

#root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  //border-radius: 8px;
  //max-height: 760px;
}

.main-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
}

.landing-info {
  width: 100%;
  max-width: 560px;
  padding: 0 32px;

  h1 {
    margin-bottom: 2rem;
    //color: #1e2022;
  }
}

.landing-info-content {
  font-size: 1.125rem;
  color: #77838f;
  text-align: justify;

  br {
    display: none;
  }
}

.no-iframed #root {
  padding: 24px;
  justify-content: space-around;

  .main-container {
    //width: 50%;
    margin: 0 auto;
  }

  .landing-info {
    margin: 0 auto;
  }

  /*
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-primary;
    z-index: -1;
    opacity: 0.5;
  }
  */
}

@media only screen and (min-width: 700px) {
  .no-iframed .main-container {
    max-width: 460px;
    //margin: auto auto;
    max-height: 720px;
    box-shadow: 0 0 35px 0 rgba(0,0,0,0.1);
  }
}


@media only screen and (max-width: 1000px) {
  .landing-info {
    display: none;
  }
}



@media only screen and (max-width: 700px) {
  .no-iframed #root {
    padding: 0;
  }
}



/* this is the flex container that will take the rest of the height */
.content-wrapper {
  display: flex;
  flex: 1;
  min-height: 0px; /* IMPORTANT: you need this for non-chrome browsers */
}

/* the container for our overflowed content */
.overflow-container {
  flex: 1;
  overflow: auto;
}



.card {
  background-color: #fff;
  box-shadow: 0 0 25px 0 rgba(0,0,0,0.1);
  border-radius: 4px;
  padding: 1.5rem;

  &.card-center {
    width: 460px;
    max-width: 90%;
    margin: 0 auto;
  }
}
