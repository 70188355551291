

.block-navigation.message {
  padding: 8px 0;
  max-width: 100%;

  .link-item {
    display: block;
    //max-width: 80%;
    margin-bottom: 16px;
    padding: 16px 20px;
    color: $color-text-dark;
    //color: $color-primary;
    //font-weight: 500;
    background-image: url(../../assets/img/bg-index.png);
    background-position: center center;
    background-size: cover;
    background-color: #f5f7f9;
    text-decoration: none;
    border-radius: 3px;
    border: 1px solid #f3f5f7;// $color-divider;
    //border-left: 3px solid $color-primary;
    transition: 0.3s ease-out;
    cursor: pointer;

    opacity: 0;
    transform: translateY(44px);
    transition: 0.5s ease-out;

    h6 {
      font-size: 1rem;
    }

    p {
      margin-bottom: 0;
      //font-size: 14px;
    }

    &:hover {
      //background-color: #f2f4f5;
      color: $color-text-dark;
      //box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      border-color: #f1f3f5;
      background-color: #f1f3f5;
    }
  }

  @for $i from 1 through 10 {
    $delay: ($i - 1) * 0.125;
    .link-item:nth-child(#{$i}) { transition: all 0.5s ease-out, opacity 0.5s ease-out #{$delay}s, transform 0.5s ease-out #{$delay}s; }
  }
  /*
  a:nth-child(1) { transition: 0.5s ease-out 0s; }
  a:nth-child(2) { transition: 0.5s ease-out 0.125s; }
  a:nth-child(3) { transition: all 0.5s ease-out, opacity 0.5s ease-out 0.25s, transform 0.5s ease-out 0.25s; }
  a:nth-child(4) { transition: all 0.5s ease-out, opacity 0.5s ease-out 0.375s, transform 0.5s ease-out 0.375s; }
  a:nth-child(5) { transition: 0.5s ease-out 0.5s; }
  a:nth-child(6) { transition: 0.5s ease-out 0.625s; }
  a:nth-child(7) { transition: 0.5s ease-out 0.75s; }
  a:nth-child(8) { transition: 0.5s ease-out 0.875s; }
  a:nth-child(9) { transition: 0.5s ease-out 1s; }
  */

  &.reveal .link-item {
    transform: translateY(0);
    opacity: 1;
  }


}

