
.block-image {
  background-color: #f1f3f7;
  padding: 6px;
  border-radius: 3px;
  display: table;

  opacity: 0;
  transform: translateY(44px);
  transition: 0.5s ease-out;

  &.reveal {
    transform: translateY(0);
    opacity: 1;
  }

  img {
    max-width: 100%;
  }
}
