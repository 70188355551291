
.ps__rail-y {
  //opacity: 0.8 !important;
}

.ps__thumb-y {
  width: 3px;
  background-color: #ddd;
}

.scrollbar-container .ps__rail-y:hover,
.scrollbar-container .ps__rail-y.ps--clicking {
  opacity: 1 !important;
}
