
.chatbox {
  list-style: none;
  //flex: 1 1 auto;
  //display: flex;
  //flex-direction: column;
  //justify-content: flex-end;
  //background: #fff;
  //max-width: 460px;
  margin: 0;
  width: 100%;
  padding: 20px;
  //box-shadow: 0 0 15px 0 rgba(0,0,0,0.03);
  //height: inherit;
  //height: 100vh;
  //max-height: 720px;
  //border-radius-bottom-right: 8px;

  //border: 1px solid #eee;

  .block-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  > li {
    margin-bottom: 1rem;
  }

  li > div {
    max-width: 80%;
  }

  .block-left + .block-left {
    margin-top: 1rem;
  }

  .block-left + .block-right {
    margin-top: 1.5rem;
  }


  .skip {
    margin-top: 8px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
  }
}

.chatbox-wrapper {
  background-color: #fff;
  border-radius: 8px;
  //border-bottom-left-radius: 8px;
  //border-bottom-right-radius: 8px;
}

.message-end {
  margin-top: 0 !important;

}


.chat-cover {
	position: relative;
	background: $color-primary url(../../assets/img/bg-index.png);
	background-position: center center;
	background-size: cover;
	min-height: 140px;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	padding: 16px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
  justify-content: flex-end;
  transition: 0.5s ease-out;

  .back {
    position: absolute;
    top: 16px;
    right: 16px;
    color: #fff;
    display: inline-block;
    //align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 22px;
    line-height: 32px;
    width: 34px;
    height: 34px;
    background-color: rgba(255,255,255,0.15);
    border-radius: 4px;
    cursor: pointer;
    opacity: 0.5;
    transition: 0.3s ease-out;

    svg {
      fill: #fff;
      width: 26px;
      transition: 0.3s ease-out;
    }

    &:hover {
      opacity: 1;

      svg {
        transform: translateX(-2px);
      }

    }
  }

	h6,
	p {
		color: #fff;
		position: relative;
    margin-bottom: 0;
    transition: 0.9s ease-out;
    max-width: 85%;
	}

	h6 {
    font-size: 1.5rem;
    line-height: 40px;
  }

  p {
    //opacity: 0.95;
    //font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

	&::before1 {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: $color-primary;
		opacity: 0.9;
  }

  &.is-shrink {
    min-height: 54px;

    .back {
      top: 10px;
    }

    h6 {
      font-size: 20px;
      line-height: 1;
    }

    p {
      transform: scale(0,0);
      display: none;
    }
  }

  + .chatbox-wrapper {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}


.brand {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 1px;
  padding-bottom: 4px;
  text-align: center;
  color: #949494;
  opacity: 0.75;
  transition: 0.3s ease-out;

  span {
    text-transform: uppercase;
    font-size: 9px;
    font-weight: 600;
  }

  &:hover {
    text-decoration: none;
    opacity: 1;
    color: #343434;
  }

}


.chat-progressbar {
  height: 3px;
  background-color: $color-primary;
  transition: 1s linear;
}
