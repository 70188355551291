

.block-social.message {
  padding: 8px 0;

  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin: 5px;
    border-radius: 10rem;
    background-color: #ccc;
    color: #fff;
    text-decoration: none;
    transition: 0.3s ease-out;

    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    font-family: "fontisto";
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    opacity: 0;
    transform: translateY(44px);
    transition: 0.5s ease-out;

    &:hover {
      transform: scale(1.15,1.15);
    }
  }

  @for $i from 1 through 10 {
    $delay: ($i - 1) * 0.125;
    a:nth-child(#{$i}) { transition: all 0.5s ease-out, opacity 0.5s ease-out #{$delay}s, transform 0.5s ease-out #{$delay}s; }
  }
  /*
  a:nth-child(1) { transition: 0.5s ease-out 0s; }
  a:nth-child(2) { transition: 0.5s ease-out 0.125s; }
  a:nth-child(3) { transition: 0.5s ease-out 0.25s; }
  a:nth-child(4) { transition: 0.5s ease-out 0.375s; }
  a:nth-child(5) { transition: 0.5s ease-out 0.5s; }
  a:nth-child(6) { transition: 0.5s ease-out 0.625s; }
  a:nth-child(7) { transition: 0.5s ease-out 0.75s; }
  a:nth-child(8) { transition: 0.5s ease-out 0.875s; }
  a:nth-child(9) { transition: 0.5s ease-out 1s; }
  */

  &.reveal a {
    transform: translateY(0);
    opacity: 1;
  }

  /*
  .icon-facebook {
    background-color: #3b5998;

    &::before {
      content: "\e918";
    }
  }

  .icon-twitter {
    background-color: #00aced;

    &::before {
      content: "\e942";
    }
  }

  .icon-instagram {
    background-color: #517fa4;

    &::before {
      content: "\e922";
    }
  }

  .icon-dribbble {
    background-color: #ea4c89;

    &::before {
      content: "\e916";
    }
  }

  .icon-linkedin {
    background-color: #007bb6;

    &::before {
      content: "\e926";
    }
  }
  */

  @each $name, $color in $brand-colors {
    .icon-#{$name} {
      background-color: $color;
    }
  }

}

