//@import-normalize;

// Google fonts
//@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');
@import url("https://cdn.jsdelivr.net/npm/fontisto@v3.0.4/css/fontisto/fontisto-brands.min.css");

@import "variables";
@import "mixins";

@import "vendor/bootstrap";
@import "vendor/flatpickr";
@import "vendor/perfect-scrollbar";

@import "layout/scafolding";
@import "layout/chatbox";

@import "content/typography";

@import "uikit/button";

@import "form/input";

@import "block/text";
@import "block/loading";
@import "block/reply";

@import "block/image";
@import "block/input";
@import "block/appointment";
@import "block/select";
@import "block/number";
@import "block/feedback";

@import "block/social";
@import "block/links";
@import "block/accordion";
@import "block/navigation";

.block-formitoad {
  background-color: #fff;
  border: 1px solid $color-divider;
  border-left: 3px solid $color-warning;
  color: $color-text;
  border-radius: 6px;
  padding: 12px 16px;
  display: table;
  margin: 50px auto;
  width: 100%;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);

  opacity: 0;
  transform: translateY(44px);
  transition: 0.3s ease-out;

  p {
    margin-bottom: 0;
  }

  &.reveal {
    transform: translateY(0);
    opacity: 1;
  }

  &:hover {
    color: #191919;
    text-decoration: none;
    box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.1);
  }
}
